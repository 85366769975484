import { memo } from "react";
import { BaseVisual } from "features/BaseVisual";
import { IDefaultVisualInput } from "services/api";
import Grid from "@material-ui/core/Grid";
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core";
import { BaseChartVisual } from "features/BaseChartVisual";

const styles = (theme: Theme) =>
  createStyles({
    visualBaseRootNormal: {
      "& .MuiCardHeader-content": {
        paddingRight: theme.spacing(4),
      },
    },
    visualBaseContentDesktop: {
      display: "flex",
      justifyContent: "center",
      minWidth: "220px",
      width: "100%",
      padding: 0,
      paddingBottom: "0px !important",
      height: "300px",
    },
  });

export const DefaultChartVisual = withStyles(styles)(
  memo(
    ({
      id,
      assets,
      periods,
      classes,
    }: Partial<IDefaultVisualInput> & WithStyles<typeof styles>) => {
      return (
        <Grid item xs={12} lg={6}>
          <BaseVisual
            input={{ id, assets, periods }}
            classes={classes}
            enableDialog
          >
            <BaseChartVisual />
          </BaseVisual>
        </Grid>
      );
    }
  )
);
