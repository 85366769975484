import { ReactNode } from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker as MuiKeyboardDatePicker,
  DatePicker as MuiDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/locale";
import { TextFieldProps } from "@material-ui/core/TextField";
import DateRangeIcon from "@material-ui/icons/DateRange";
import theme from "views/Theme";
import { useIsDesktop } from "utils/hooks";
import { yesterday, format } from "features/Inputs";

type DatePickerProps = {
  id: string;
  label?: string;
  value: Date | null;
  onChange: (date: Date | null) => void;
  error?: boolean;
  errorText?: string;
  onError?: (error: ReactNode) => void;
  maxDate?: Date;
} & Pick<TextFieldProps, "classes">;

export const DatePicker = ({
  id,
  label,
  value,
  onChange,
  error,
  errorText,
  onError,
  maxDate = yesterday(),
  classes,
}: DatePickerProps) => {
  const isDesktop = useIsDesktop();

  const maxDateStr = format(maxDate);
  const maxDateMessage = `Data deve ser inferior ou igual a ${maxDateStr}`;
  const invalidDateMessage = `Data inválida. Exemplo válido: ${maxDateStr}`;

  const extraProps = error
    ? {
        classes,
        error,
        helperText: errorText,
      }
    : { classes };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
      {isDesktop ? (
        <MuiKeyboardDatePicker
          id={id}
          value={value}
          variant="inline"
          autoOk
          onChange={onChange}
          onError={onError}
          format="P"
          margin="normal"
          label={label}
          allowKeyboardControl={false}
          invalidDateMessage={invalidDateMessage}
          maxDate={maxDate}
          maxDateMessage={maxDateMessage}
          mask="__/__/____"
          keyboardIcon={
            <DateRangeIcon
              fontSize="small"
              style={{ color: theme.palette.text.secondary }}
            />
          }
          KeyboardButtonProps={{ "aria-label": "Trocar data" }}
          {...extraProps}
        />
      ) : (
        <MuiDatePicker
          id={id}
          value={value}
          variant="inline"
          autoOk
          onChange={onChange}
          onError={onError}
          format="P"
          margin="normal"
          label={label}
          allowKeyboardControl={false}
          disableFuture
          invalidDateMessage={invalidDateMessage}
          maxDate={maxDate}
          maxDateMessage={maxDateMessage}
          {...extraProps}
        />
      )}
    </MuiPickersUtilsProvider>
  );
};
