import { ptBR } from "date-fns/locale";
import { format as fmt, parseISO } from "date-fns";

export const format = (date: Date, formatStr = "P") => {
  return fmt(date, formatStr, { locale: ptBR });
};

export const normalize = (date: Date) => {
  date.setHours(0, 0, 0, 0);
};

export const yesterday = () => {
  const date = new Date();
  normalize(date);
  date.setDate(date.getDate() - 1);
  return date;
};

export const toISO = (date: Date) => {
  return fmt(date, "yyyy-MM-dd'T'HH:mm:ss");
};

export const fromISO = (isoDate: string) => {
  return parseISO(isoDate);
};
