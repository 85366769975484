import { ReactElement, useCallback, useEffect, useRef } from "react";
import { SideBar } from "features/SideBar";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { updatePanels, selectPanel } from "./redux";
import { cloneElement } from "react";
import { TPanelData } from "services/api";
import { IBasePanelChildrenProps, TPanelFormChildrenProps } from "./types";
import { useQueryParams } from "use-query-params";
import { TPanel, TSelector } from "./redux/slice";

const buildQp = (selectors: TPanel["selectors"]) => {
  const qp: Record<string, TSelector["value"]> = {};
  if (selectors) {
    for (let key in selectors) {
      if (selectors[key].status === "enabled") {
        qp[key] = selectors[key].value;
      }
    }
  }
  return qp;
};

export interface IPanelFormProps<T> extends IBasePanelChildrenProps<T> {
  disabled?: boolean;
  children:
    | ReactElement<TPanelFormChildrenProps<T>>
    | ReactElement<TPanelFormChildrenProps<T>>[];
}

export const PanelForm = <T extends TPanelData>({
  panelId,
  data,
  children,
}: IPanelFormProps<T>) => {
  // eslint-disable-next-line
  const [_, setQp] = useQueryParams({});
  const panelWasSubmitting = useRef<boolean>(false);

  const dispatch = useAppDispatch();
  const panel = useAppSelector((state) => selectPanel(state, { panelId }));

  const handleSubmit = () => {
    dispatch(updatePanels({ [panelId]: { isSubmitting: true } }));
  };

  // Update query strings at the end of form submission to avoid multiple
  // updates to browser history
  useEffect(() => {
    if (panel) {
      // if (panelWasSubmitting.current && !panel.isSubmitting) {
      //   setQp(buildQp(panel.selectors));
      // }
      panelWasSubmitting.current = panel.isSubmitting;
    }
  }, [panel, setQp]);

  const parseChildren = useCallback(
    <T,>(children: IPanelFormProps<T>["children"]) => {
      return Array.isArray(children) ? children : [children];
    },
    []
  );

  return (
    <SideBar
      isLoading={false}
      formErrorMessage={panel ? panel.errorMessage : ""}
      isSubmitting={panel ? panel.isSubmitting : false}
      handleSubmit={handleSubmit}
    >
      {parseChildren(children).map((child, idx) =>
        cloneElement(child, { key: idx, panelId, data })
      )}
    </SideBar>
  );
};
