import { useEffect, useRef } from "react";
import {
  PanelSelect,
  PanelPeriodSelect,
  TPanelFormChildrenProps,
} from "features/BasePanel";
import { IComparisonPanelData, IPanelPeriod } from "services/api";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { updateSelectors, selectSelectorProp } from "features/BasePanel/redux";
import { sub } from "date-fns";
import { fromISO, toISO, format } from "features/Inputs";
import { PanelState } from "features/BasePanel/PanelPeriodSelect/types";

const genSecondaryPeriods = <P extends PanelState>(period: P) => {
  const gen = (period: P, years: 0 | 1 = 0) => {
    const p = JSON.parse(JSON.stringify(period)) as P;
    const startDate = sub(fromISO(p.startDate), { years });
    const endDate = sub(fromISO(p.endDate), { years });
    p.startDate = toISO(startDate);
    p.endDate = toISO(endDate);
    return {
      id: years.toString(),
      label: years ? "1 ano antes" : "Mesmo período",
      startIso: p.startDate,
      startLabel: format(startDate),
      endIso: p.endDate,
      endLabel: format(sub(endDate, { days: 1 })),
      freq: p.freq,
    } as IPanelPeriod;
  };
  return [gen(period), gen(period, 1)];
};

export const PeriodSelectors = ({
  panelId,
  data,
}: TPanelFormChildrenProps<IComparisonPanelData>) => {
  const dispatch = useAppDispatch();

  const primaryPeriod = useAppSelector((state) =>
    selectSelectorProp(state, {
      panelId,
      selectorId: "primaryPeriod",
      prop: "value",
    })
  ) as PanelState | undefined;

  const secondaryPeriod = useAppSelector((state) =>
    selectSelectorProp(state, {
      panelId,
      selectorId: "secondaryPeriod",
      prop: "value",
    })
  ) as IPanelPeriod | undefined;

  const periods = useRef<IPanelPeriod[]>();
  periods.current = primaryPeriod && genSecondaryPeriods(primaryPeriod);

  useEffect(() => {
    const newSecondaryPeriod =
      periods.current &&
      secondaryPeriod &&
      periods.current[Number(secondaryPeriod.id)];

    if (
      newSecondaryPeriod &&
      secondaryPeriod &&
      (newSecondaryPeriod.startIso !== secondaryPeriod.startIso ||
        newSecondaryPeriod.endIso !== secondaryPeriod.endIso ||
        newSecondaryPeriod.freq !== secondaryPeriod.freq)
    ) {
      dispatch(
        updateSelectors({
          [panelId]: {
            selectors: {
              secondaryPeriod: {
                value: newSecondaryPeriod,
              },
            },
          },
        })
      );
    }
  }, [panelId, primaryPeriod, secondaryPeriod, dispatch]);

  return (
    <>
      {data && (
        <>
          <PanelPeriodSelect
            id="primaryPeriod"
            panelId={panelId}
            label="Período:"
            title="Selecione um período"
            idField="id"
            labelField="label"
            data={data.primaryPeriods}
            initState={{ status: "enabled" }}
            maxDatapointNum={{ H: 36, D: 36, MS: 12 }}
          />
          {periods.current && (
            <PanelSelect
              id="secondaryPeriod"
              panelId={panelId}
              label="Comparado com:"
              title="Selecione um período"
              idField="id"
              labelField="label"
              data={periods.current}
              initState={{
                status: "enabled",
                value: periods.current[0],
              }}
              mb={4}
            />
          )}
        </>
      )}
    </>
  );
};
