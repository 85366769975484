import { useState } from "react";
import { useIsDesktop } from "utils/hooks";
import { FormMobile } from "./FormMobile";
import { FormContent } from "./FormContent";
import { IFormProps } from "./types";

export const Form = ({
  children,
  isLoading,
  isSubmitting,
  disabled = false,
  formErrorMessage,
  handleSubmit,
}: IFormProps) => {
  const isDesktop = useIsDesktop();
  const hasFormError = Boolean(formErrorMessage);
  const [drawerOpened, setDrawerOpened] = useState<boolean>(true);

  return (
    <>
      {isDesktop ? (
        <FormContent
          isLoading={isLoading}
          formErrorMessage={formErrorMessage}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          disabled={disabled}
        >
          {children}
        </FormContent>
      ) : (
        <FormMobile
          title="Configurar Comparação"
          isLoading={isLoading}
          hasFormError={hasFormError}
          drawerOpened={drawerOpened}
          setDrawerOpened={setDrawerOpened}
        >
          <FormContent
            isLoading={isLoading}
            formErrorMessage={formErrorMessage}
            handleSubmit={() => {
              handleSubmit();
              setDrawerOpened(false);
            }}
            isSubmitting={isSubmitting}
            disabled={disabled}
          >
            {children}
          </FormContent>
        </FormMobile>
      )}
    </>
  );
};
