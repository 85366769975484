import { createStyles, Theme, fade } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    desktop: {
      margin: theme.spacing(2),
      marginBottom: 0,
      height: theme.spacing(15),
      minWidth: 0,
    },
    mobile: {
      width: `calc(100% - ${theme.spacing(4)}px)`,
      margin: `${theme.spacing(3)}px auto 0`,
      minHeight: theme.spacing(17),
    },
    itemDesktop: {
      display: "flex",
      minWidth: 0,
    },
    scrollBarHorizontal: {
      maxWidth: "100%",
      width: "100%",
      overflow: "auto",
      whiteSpace: "nowrap",
      paddingBottom: 8,
      "& > button": {
        marginRight: 16,
      },
      "& > button:last-child": {
        marginRight: 0,
      },
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    onScrollTrigger: {
      position: "fixed",
      zIndex: theme.zIndex.appBar,
      top: theme.spacing(11),
      width: `calc(100% - ${theme.spacing(4)}px)`,
      padding: `${theme.spacing(2)}px 0`,
      backgroundColor: fade(theme.palette.background.default, 0.8),
    },
    button: {
      backgroundColor: "transparent",
      boxShadow: "none",
      "&:hover": {
        backgroundColor: "transparent",
        boxShadow: "none",
      },
      fontSize: 14,
    },
  });
