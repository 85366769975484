import {
  withStyles,
  createStyles,
  Theme,
  WithStyles,
  fade,
} from "@material-ui/core";
import Box, { BoxProps } from "@material-ui/core/Box";
import { SelectTreeView, ISelectTreeProps } from "views/SelectTreeView";
import { treeToArray, getNodeById } from "utils/tree";
import { PartialKey } from "utils/types";
import { useQueryParam, StringParam } from "use-query-params";
import { ReactNode } from "react";
import { InputProps } from "./types";

type TSelectItem = {
  id: string;
  children?: TSelectItem[];
};
type TSelectData = TSelectItem | TSelectItem[];

const validateValueId = (
  data: TSelectData | undefined,
  value: string | null | undefined
): string | undefined => {
  if (data && value && getNodeById(data, value)) {
    return value;
  }
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      cursor: "pointer",
      "&.Mui-disabled": {
        cursor: "pointer",
      },
      "&.MuiInput-underline:hover:before": {
        borderBottom: `2px solid ${theme.palette.getContrastText(
          theme.palette.primary.dark
        )} !important`,
      },
      "&.MuiInput-underline.Mui-disabled:before": {
        borderBottom: `1px solid ${fade(theme.palette.text.disabled, 0.1)}`,
      },
    },
  });

export type SelectProps = {
  data: TSelectData;
  idField: string;
  labelField: string;
  nestedField?: string;
  children?: ReactNode;
} & Omit<InputProps, "value"> &
  Omit<
    ISelectTreeProps,
    | "elementId"
    | "customInputRootCSSClass"
    | "onChange"
    | "data"
    | "dataFlatted"
    | "dataIdField"
    | "dataLabelField"
  > &
  PartialKey<WithStyles<typeof styles>, "classes"> &
  Omit<BoxProps, "onChange" | "onOpen" | "onClose">;

export const Select = withStyles(styles)(
  ({
    id,
    panelId,
    label,
    title,
    status,
    value,
    onChange,
    open,
    onOpen,
    onClose,
    searchLabel,
    idField,
    labelField,
    nestedField,
    size,
    variant,
    data,
    ml = 2,
    mt = 2,
    mr = 2,
    mb = 1,
    extraItem,
    classes,
    ...boxProps
  }: SelectProps) => {
    // const [qp] = useQueryParam(id, StringParam);
    // const validatedQp = validateValueId(data, qp);

    return (
      <>
        {data && value && status === "enabled" && (
          <Box ml={ml} mt={mt} mr={mr} mb={mb} {...boxProps}>
            <SelectTreeView
              elementId={id}
              label={label}
              title={title}
              searchLabel={searchLabel}
              data={data}
              dataFlatted={
                Array.isArray(data)
                  ? data
                  : nestedField
                  ? treeToArray(data, [idField, labelField], nestedField)
                  : []
              }
              dataIdField={idField}
              dataLabelField={labelField}
              value={value}
              onChange={onChange}
              open={open}
              onOpen={onOpen}
              onClose={onClose}
              size={size}
              variant={variant}
              customInputRootCSSClass={classes?.root}
              extraItem={extraItem}
            />
          </Box>
        )}
      </>
    );
  }
);
