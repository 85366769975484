import { ReactNode } from "react";
import ErrorBoundary from "utils/ErrorBoundary";
import Grid from "@material-ui/core/Grid";
import { Theme, createStyles, withStyles, WithStyles } from "@material-ui/core";
import { Form } from "features/Form";
import { desktopBreakpoint } from "utils/hooks/useIsDesktop";

export const SIDE_BAR_WIDTH = 316;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up(desktopBreakpoint)]: {
        minHeight: "100%",
        width: SIDE_BAR_WIDTH,
        minWidth: SIDE_BAR_WIDTH,
        overflow: "auto",
        marginLeft: theme.spacing(1),
        backgroundColor: theme.palette.primary.dark,
      },
    },
  });

export interface SideBarProps {
  children: ReactNode;
  isLoading: boolean;
  formErrorMessage: string;
  isSubmitting: boolean;
  disabled?: boolean;
  handleSubmit: () => void;
}

export const SideBar = withStyles(styles)(
  ({
    children,
    isLoading,
    isSubmitting,
    disabled = false,
    handleSubmit,
    formErrorMessage,
    classes,
  }: SideBarProps & WithStyles<typeof styles>) => {
    return (
      <ErrorBoundary>
        <Grid
          item
          container
          justify="flex-start"
          direction="column"
          alignItems="flex-start"
          wrap="nowrap"
          classes={classes}
        >
          <Form
            isLoading={isLoading}
            formErrorMessage={formErrorMessage}
            isSubmitting={isSubmitting}
            disabled={disabled}
            handleSubmit={handleSubmit}
          >
            {children}
          </Form>
        </Grid>
      </ErrorBoundary>
    );
  }
);
