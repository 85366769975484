import { ComponentProps } from "react";
import { DatePicker } from "features/Inputs";
import { add, sub } from "date-fns";

type PanelDatePickerProps = {
  bypass?: boolean;
} & ComponentProps<typeof DatePicker>;

export const PanelDatePicker = ({
  bypass = false,
  value: valueProp,
  maxDate,
  onChange,
  ...other
}: PanelDatePickerProps) => {
  const value = bypass
    ? valueProp
    : valueProp !== null
    ? sub(valueProp, { days: 1 })
    : null;

  const handleChange = (date: Date | null) => {
    if (bypass) {
      onChange(date);
    } else {
      onChange(date !== null ? add(date, { days: 1 }) : null);
    }
  };

  return (
    <DatePicker
      value={value}
      onChange={handleChange}
      maxDate={maxDate}
      {...other}
    />
  );
};
