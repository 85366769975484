import { ReactNode } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setIsNotAuthenticated, selectUser } from "redux/auth";
import * as Services from "services";
import { ConsoleLogger as Logger } from "utils/logger";
import { useHistory } from "react-router-dom";
import { default as MuiAppBar } from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Profile } from "./Profile";
import Chip from "@material-ui/core/Chip";
import { useIsDesktop } from "utils/hooks";
import { NavMenu } from "features/NavMenu";
import Box from "@material-ui/core/Box";
import ScrollTrigger from "utils/ScrollTrigger";
import { GENERATION_PANEL_ID } from "features/GenerationPanel";
import {
  createStyles,
  WithStyles,
  withStyles,
  Theme,
  fade,
} from "@material-ui/core";

const logger = new Logger("AppBar");

export const styles = (theme: Theme) =>
  createStyles({
    toolbar: {
      paddingLeft: theme.spacing(1),
      minHeight: theme.spacing(8),
    },
    menuButton: {
      maxHeight: theme.spacing(7),
      maxWidth: theme.spacing(7),
      padding: theme.spacing(1),
      margin: 0,
    },
    versionDisplay: {
      backgroundColor: `${fade(theme.palette.primary.main, 0.2)} !important`,
      color: `${fade(theme.palette.text.primary, 0.6)} !important`,
      fontWeight: "bold",
      margin: theme.spacing(1),
      borderRadius: `${theme.shape.borderRadius}px !important`,
      wordSpacing: theme.spacing(1 / 2),
      paddingTop: `${theme.spacing(1 / 4)}px !important`,
    },
  });

export const AppBar = withStyles(styles)(
  ({
    children,
    classes,
  }: { children?: ReactNode } & WithStyles<typeof styles>) => {
    // FIX: panelViewHTMLElement is coming null
    const panelViewHTMLElement: HTMLElement | null = document.getElementById(
      GENERATION_PANEL_ID
    );

    const history = useHistory();
    const isDesktop = useIsDesktop();

    const user = useAppSelector(selectUser) || { name: "", image: "" };
    const dispatch = useAppDispatch();

    const handleLogout = async () => {
      if (user?.name) {
        try {
          await Services.Auth.getInstance().signOut();
        } catch (error) {
          logger.error(error);
        } finally {
          dispatch(setIsNotAuthenticated());
        }
      } else {
        history.push("/");
      }
    };

    const handleProfile = () => {};

    return (
      <MuiAppBar position="static" elevation={0} color="secondary" id="app-bar">
        <Toolbar className={classes.toolbar}>
          <NavMenu />
          <Chip
            label={
              isDesktop ? `Beta (v${process.env.REACT_APP_VERSION})` : "Beta"
            }
            size="small"
            classes={{ sizeSmall: classes.versionDisplay }}
          />
          <Box display="flex" width="100%" minWidth={0} overflow="hidden">
            {panelViewHTMLElement && (
              <ScrollTrigger target={panelViewHTMLElement}>
                {children}
              </ScrollTrigger>
            )}
          </Box>
          <Profile
            user={user}
            handleLogout={handleLogout}
            handleProfile={handleProfile}
            toggleGreetings={Boolean(children)}
          />
        </Toolbar>
      </MuiAppBar>
    );
  }
);
