import ToolbarView from "views/ToolbarView";
import { useIsDesktop } from "utils/hooks";
import clsx from "clsx";
import {
  IBasePanelChildrenProps,
  PanelSelect,
  PanelPeriodSelect,
} from "features/BasePanel";
import { IGenerationPanelData } from "services/api";
import { withStyles, WithStyles } from "@material-ui/core";
import { styles } from "./styles";

type TPanelSelectorsProps = IBasePanelChildrenProps<IGenerationPanelData>;

export const PanelSelectors = withStyles(styles)(
  ({
    panelId,
    data,
    classes,
  }: TPanelSelectorsProps & WithStyles<typeof styles>) => {
    const isDesktop = useIsDesktop();

    return (
      <ToolbarView
        wrap="nowrap"
        direction={isDesktop ? "row" : "column"}
        justify={isDesktop ? "space-between" : "flex-start"}
        alignItems={isDesktop ? "center" : "stretch"}
        className={clsx({
          [classes.desktop]: isDesktop,
          [classes.mobile]: !isDesktop,
        })}
      >
        {data && (
          <>
            <PanelSelect
              id="asset"
              panelId={panelId}
              label="Ativo:"
              title="Selecione o Ativo"
              searchLabel="Pesquisar"
              idField="id"
              labelField="label"
              nestedField="children"
              data={data.assets}
              // TODO: improve value initialization
              initState={{ status: "enabled", value: data.assets }}
              size="lg"
              mt={0}
              ml={0}
              mr={isDesktop ? 2 : 0}
              className={clsx({
                [classes.itemDesktop]: isDesktop,
              })}
            />
            <PanelPeriodSelect
              id="period"
              panelId={panelId}
              label="Período:"
              title="Selecione um período"
              idField="id"
              labelField="label"
              data={data.periods}
              initState={{ status: "enabled" }}
              maxDatapointNum={{ H: 36, D: 36, MS: 36 }}
              size="md"
              mr={isDesktop ? 0 : 0}
              ml={isDesktop ? 2 : 0}
              className={clsx({
                [classes.itemDesktop]: isDesktop,
              })}
            />
          </>
        )}
      </ToolbarView>
    );
  }
);
