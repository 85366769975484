import { useEffect, useState, cloneElement, ReactElement } from "react";
import { getPanel, TPanelData } from "services/api";
import ErrorBoundary from "utils/ErrorBoundary";
import LoaderView from "views/LoaderView";
import { GenericError } from "utils/errors";
import { errorMessageBuilder } from "utils/errors/helpers";
import { FriendlyErrorMessages } from "utils/errors/messages";
import ErrorView from "views/ErrorView";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { selectPanel, setPanels } from "./redux";
import { IBasePanelProps } from "./types";

export const BasePanel = <T extends TPanelData>({
  panelId,
  children,
}: IBasePanelProps<T>) => {
  const dispatch = useAppDispatch();

  const panel = useAppSelector((state) => selectPanel(state, { panelId }));

  const [data, setData] = useState<T>();
  const [error, setError] = useState<GenericError>();

  useEffect(() => {
    let isMounded = true;

    getPanel<T>(panelId)
      .then((data) => {
        if (isMounded && data) {
          setData(data);
        }
      })
      .catch((reason) => {
        if (isMounded) {
          setError(reason);
        }
      });

    return () => {
      isMounded = false;
    };
  }, [panelId]);

  // Initialize panel state if not done before. Otherwise, preserve its state
  useEffect(() => {
    if (!panel) {
      dispatch(
        setPanels({
          [panelId]: {
            selectors: {},
            isSubmitting: false,
            errorMessage: "",
          },
        })
      );
    }
  }, [panel, panelId, dispatch]);

  if (!data) {
    return <LoaderView open />;
  }

  return (
    <ErrorBoundary>
      {error ? (
        <ErrorView
          title={FriendlyErrorMessages.GENERIC_TITLE}
          handleRetry={() => window.location.reload()}
          errorMessage={errorMessageBuilder(error)}
          errorExtraData={{
            message: JSON.stringify({
              message: error.message,
              details: error.extraData?.message,
            }),
            instructions: error.extraData?.instructions,
          }}
          withDialog={true}
        />
      ) : (
        <>
          {children.map((child, idx) =>
            cloneElement(child, { key: idx, data })
          )}
        </>
      )}
    </ErrorBoundary>
  );
};
