import { useEffect, useState } from "react";
import FullScreenView from "views/FullScreenView";
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import { PanelToolbar } from "features/PanelToolbar";
import { AppBar } from "features/AppBar";
import { Tabs } from "features/Tabs";
import { GenerationPanel } from "features/GenerationPanel";
import { basename } from "path";
import { ComparisonPanel } from "features/ComparisonPanel";

const panels = [
  { id: "generation", label: "Geração" },
  { id: "comparison", label: "Comparação" },
];

export const PanelsScene = () => {
  const location = useLocation();
  const history = useHistory();
  const { path } = useRouteMatch();

  const [panelId, setPanelId] = useState<string | undefined>();

  const selPanel = (panelId: string) => {
    const pathname = `${path}/${panelId}`;
    if (pathname !== location.pathname) {
      setPanelId(panelId);
      history.push({ pathname });
    }
  };

  // Update internal state according to `pathname`
  useEffect(() => {
    if (location.pathname !== path) {
      const panelId = basename(location.pathname);
      setPanelId(panelId);
    }
  }, [path, location.pathname, setPanelId]);

  return (
    <FullScreenView>
      <AppBar />
      <PanelToolbar>
        {panelId && (
          <Tabs
            optionId={panelId}
            options={panels}
            selOption={selPanel}
            title="Painéis"
          />
        )}
      </PanelToolbar>
      <Switch>
        <Route path={`${path}/generation`}>
          <GenerationPanel />
        </Route>
        <Route path={`${path}/comparison`}>
          <ComparisonPanel />
        </Route>
        <Route>
          <Redirect to={`${path}/${panels[0].id}`} />
        </Route>
      </Switch>
    </FullScreenView>
  );
};
