import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Button from "views/ButtonView";
import { FormContentLoader } from "./FormContentLoader";
import { createStyles, withStyles, WithStyles, Theme } from "@material-ui/core";
import { desktopBreakpoint } from "utils/hooks/useIsDesktop";
import { IFormProps } from "./types";

const StyledAlert = withStyles((theme: Theme) => ({
  filledError: {
    backgroundColor: "rgba(255, 23, 68, .6)",
  },
}))(Alert);

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up(desktopBreakpoint)]: {
        overflow: "auto",
      },
    },
    content: {
      [theme.breakpoints.down(desktopBreakpoint)]: {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
      },
    },
  });

export const FormContent = withStyles(styles)(
  ({
    children,
    isLoading,
    formErrorMessage,
    isSubmitting,
    disabled = false,
    handleSubmit,
    classes,
  }: IFormProps & WithStyles<typeof styles>) => {
    return isLoading ? (
      <FormContentLoader className={classes.root} />
    ) : (
      <Grid
        item
        container
        justify="flex-start"
        style={{
          minHeight: "100%",
        }}
        direction="column"
        alignItems="stretch"
        wrap="nowrap"
      >
        <Box className={classes.root}>
          {Boolean(formErrorMessage) && (
            <Box p={2} pb={0}>
              <StyledAlert severity="error" variant="filled">
                {formErrorMessage}
              </StyledAlert>
            </Box>
          )}
        </Box>
        <Box className={classes.content}>{children}</Box>
        <Box className={classes.content} p={2}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={disabled || isSubmitting}
            fullWidth
            onClick={handleSubmit}
            isSubmitting={isSubmitting}
          >
            APLICAR
          </Button>
        </Box>
      </Grid>
    );
  }
);
