import { FC } from "react";
import Grid from "@material-ui/core/Grid";
import { useIsDesktop } from "utils/hooks";
import theme from "views/Theme";
import { IVisualListViewProps } from "./types";

const VisualListView: FC<IVisualListViewProps> = ({
  children,
  maxWidthDesktop,
  paddingDesktop,
  ...rest
}) => {
  const isDesktop = useIsDesktop();
  const style = isDesktop
    ? {
        width: maxWidthDesktop || "100%",
        minWidth: maxWidthDesktop || "none",
        padding: paddingDesktop,
      }
    : {
        height: "auto",
        width: "100%",
        paddingRight: theme.spacing(2),
      };

  return (
    <Grid item container justify="flex-start" style={style} {...rest}>
      {children}
    </Grid>
  );
};

export default VisualListView;
