import { BasePanel } from "features/BasePanel";
import { PanelSelectors } from "./PanelSelectors";
import { PanelVisuals } from "./PanelVisuals";
import { IGenerationPanelData } from "services/api";
import MainContentView from "views/MainContentView";

export const GENERATION_PANEL_ID = "generation-panel-id";

export const GenerationPanel = () => {
  const panelId = "0";
  return (
    <>
      <MainContentView
        id={GENERATION_PANEL_ID}
        //ref={panelViewRef}
        container
        direction="row"
        alignContent="flex-start"
      >
        <BasePanel<IGenerationPanelData> panelId={panelId}>
          <PanelSelectors panelId={panelId} />
          <PanelVisuals panelId={panelId} />
        </BasePanel>
      </MainContentView>
    </>
  );
};
