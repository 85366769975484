import Box from "@material-ui/core/Box";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import ButtonView from "views/ButtonView";
import { PanelSelect, TPanelFormChildrenProps } from "features/BasePanel";
import { desktopBreakpoint } from "utils/hooks/useIsDesktop";
import { withStyles, Theme, fade } from "@material-ui/core";
import { IComparisonPanelData } from "services/api";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { selectSelector, updateSelectors } from "features/BasePanel/redux";

const StyledButton = withStyles((theme: Theme) => ({
  containedSecondary: {
    color: theme.palette.text.disabled,
    backgroundColor: "transparent",
    boxShadow: "none !important",
    borderWidth: "1px",
    borderStyle: "solid",
    [theme.breakpoints.up(desktopBreakpoint)]: {
      borderColor: "#0D2F40",
    },
    boderColor: fade(theme.palette.text.disabled, 0.1),
    "&:hover": {
      color: theme.palette.text.primary,
      backgroundColor: fade(theme.palette.text.disabled, 0.05),
    },
    "&:active, &:disabled": {
      color: theme.palette.text.primary,
      backgroundColor: fade(theme.palette.text.disabled, 0.1),
      opacity: 1,
    },
  },
}))(ButtonView);

export const MetricSelectors = ({
  panelId,
  data,
}: TPanelFormChildrenProps<IComparisonPanelData>) => {
  const dispatch = useAppDispatch();
  const secondaryMetricSelector = useAppSelector((state) =>
    selectSelector(state, { panelId, selectorId: "secondaryMetric" })
  );

  // Enable and disable the secondary metric and keep its query string parameter
  // in sync
  const handleEnableSecondaryMetric = () => {
    if (secondaryMetricSelector) {
      if (secondaryMetricSelector.status === "enabled") {
        dispatch(
          updateSelectors({
            [panelId]: {
              selectors: {
                secondaryMetric: { status: "disabled" },
              },
            },
          })
        );
      } else {
        dispatch(
          updateSelectors({
            [panelId]: {
              selectors: {
                secondaryMetric: { status: "enabled" },
              },
            },
          })
        );
      }
    }
  };

  return (
    <>
      {data && (
        <>
          <PanelSelect
            id="primaryMetric"
            panelId={panelId}
            label="Métrica:"
            title="Selecione uma métrica"
            idField="id"
            labelField="label"
            data={data.metricList}
            initState={{
              status: "enabled",
              value: data.metricList[0],
            }}
          />
          <PanelSelect
            id="secondaryMetric"
            panelId={panelId}
            label="Comparada com:"
            title="Selecione uma métrica"
            idField="id"
            labelField="label"
            data={data.metricList}
            initState={{
              status: "disabled",
              value: data.metricList[0],
            }}
          />
          <Box mt={2} mb={3} mr={2} ml={2}>
            <StyledButton
              type="submit"
              variant="contained"
              color="secondary"
              disabled={false}
              fullWidth
              onClick={handleEnableSecondaryMetric}
              isSubmitting={false}
              startIcon={
                secondaryMetricSelector &&
                secondaryMetricSelector.status === "enabled" ? (
                  <DeleteIcon />
                ) : (
                  <AddIcon />
                )
              }
            >
              {secondaryMetricSelector &&
              secondaryMetricSelector.status === "enabled"
                ? "REMOVER MÉTRICA"
                : "ADICIONAR MÉTRICA"}
            </StyledButton>
          </Box>
        </>
      )}
    </>
  );
};
