import { BasePanel } from "features/BasePanel";
import { IComparisonPanelData } from "services/api";
import { PanelForm } from "features/BasePanel";
import { PanelVisuals } from "./PanelVisuals";
import { useAppDispatch } from "redux/hooks";
import { MetricSelectors } from "./MetricSelectors";
import { AssetSelectors } from "./AssetSelectors";
import { PeriodSelectors } from "./PeriodSelectors";
import { useIsDesktop } from "utils/hooks";
import { withStyles, WithStyles } from "@material-ui/core";
import { styles } from "./styles";
import MainContentView from "views/MainContentView";
import { useEffect } from "react";
import { updatePanels } from "features/BasePanel/redux";

export const ComparisonPanel = withStyles(styles)(
  ({ classes }: WithStyles<typeof styles>) => {
    const isDesktop = useIsDesktop();
    const dispatch = useAppDispatch();

    // Trigger a request to backend during initialization
    useEffect(() => {
      dispatch(updatePanels({ [panelId]: { isSubmitting: true } }));
    }, [dispatch]);

    const panelId = "1";

    return (
      <>
        <MainContentView
          className={!isDesktop && classes ? classes.mainContent : ""}
          container={isDesktop}
          direction={isDesktop ? "column" : undefined}
          alignContent={isDesktop ? "flex-start" : undefined}
        >
          <BasePanel<IComparisonPanelData> panelId={panelId}>
            <PanelVisuals panelId={panelId} />
            <PanelForm panelId={panelId}>
              <AssetSelectors panelId={panelId} />
              <PeriodSelectors panelId={panelId} />
              <MetricSelectors panelId={panelId} />
            </PanelForm>
          </BasePanel>
        </MainContentView>
      </>
    );
  }
);
