import { FC, Fragment } from "react";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import { IScrollTriggerProps } from "./types";

export const ScrollTrigger: FC<IScrollTriggerProps> = ({
  target,
  hideOnTrigger = false,
  threshold,
  children,
}) => {
  const trigger = useScrollTrigger({
    target,
    disableHysteresis: true,
    threshold,
  });

  if (hideOnTrigger) {
    return trigger && hideOnTrigger ? (
      <Fragment />
    ) : (
      <Fragment>{children}</Fragment>
    );
  } else {
    return trigger ? <Fragment>{children}</Fragment> : <Fragment />;
  }
};

export default ScrollTrigger;
