import React from "react";
import Grid, { GridProps } from "@material-ui/core/Grid";

const ToolbarView: React.FC<GridProps> = React.forwardRef(
  ({ children, ...rest }, ref: any) => {
    return (
      <Grid container {...rest} ref={ref}>
        {children}
      </Grid>
    );
  }
);

export default ToolbarView;
