import { createStyles, Theme } from "@material-ui/core";

export const appBarSpacing = 8;
export const toolbarPanelSpacing = 6;

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: `calc(100vh - ${theme.spacing(
        appBarSpacing + toolbarPanelSpacing
      )}px)`,
      overflowY: "auto",
    },
  });
