import { ComponentType, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  setSelectors,
  updateSelectors,
  selectSelector,
} from "features/BasePanel/redux";
import { PanelInput } from "./types";

// TODO: validate value during initialization and update

// Link an input with panel state
// For more information about how to deal with prop types when implementing a
// HOC, please refer to
// https://react-typescript-cheatsheet.netlify.app/docs/hoc/excluding_props/
export const makePanelInput = <P extends PanelInput>(
  Component: ComponentType<P>
) => (props: Omit<P, "status" | "value" | "onChange">): JSX.Element => {
  const { id, panelId, initState, ...other } = props;

  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) =>
    selectSelector(state, { panelId, selectorId: id })
  );

  // Initialization
  useEffect(() => {
    if (!selector) {
      dispatch(
        setSelectors({
          [panelId]: {
            selectors: {
              [id]: {
                status: initState.status,
                value: initState.value,
              },
            },
          },
        })
      );
    }
  }, [panelId, id, initState, selector, dispatch]);

  const onChange = (newValue: string) => {
    dispatch(
      updateSelectors({
        [panelId]: {
          selectors: {
            [id]: {
              value: newValue,
            },
          },
        },
      })
    );
  };

  const injectedProps = {
    id,
    panelId,
    status: selector ? selector.status : initState.status,
    value: selector ? selector.value : initState.value,
    onChange,
    ...other,
  } as P;

  return <Component {...injectedProps} />;
};
