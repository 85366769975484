import { ReactNode } from "react";
import Fab from "@material-ui/core/Fab";
import Drawer from "@material-ui/core/Drawer";
import TuneIcon from "@material-ui/icons/Tune";
import DrawerTitleView from "views/DrawerTitleView";
import ErrorIcon from "@material-ui/icons/Error";
import { createStyles, withStyles, WithStyles, Theme } from "@material-ui/core";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: "fixed",
      bottom: theme.spacing(1),
      right: theme.spacing(1),
      margin: theme.spacing(1),
    },
    extendedIcon: {
      //marginRight: theme.spacing(1),
    },
    drawerPaper: {
      borderTopLeftRadius: theme.spacing(1),
      borderTopRightRadius: theme.spacing(1),
    },
  });

interface IFormMobileProps {
  title?: string;
  isLoading: boolean;
  drawerOpened: boolean;
  setDrawerOpened: (drawerOpened: boolean) => void;
  hasFormError?: boolean;
  children: ReactNode;
}

export const FormMobile = withStyles(styles)(
  ({
    title,
    isLoading,
    hasFormError = false,
    drawerOpened,
    setDrawerOpened,
    classes,
    children,
  }: IFormMobileProps & WithStyles<typeof styles>) => {
    return (
      <>
        <Fab
          variant="round"
          color="primary"
          className={classes.root}
          onClick={() => setDrawerOpened(true)}
          disabled={isLoading}
        >
          {hasFormError ? (
            <ErrorIcon className={classes.extendedIcon} color="error" />
          ) : (
            <TuneIcon className={classes.extendedIcon} />
          )}
          {/* {isLoading ? "Carregando" : "Configurar"} */}
        </Fab>
        <Drawer
          anchor="bottom"
          open={drawerOpened}
          onClose={() => setDrawerOpened(false)}
          classes={{ paper: classes.drawerPaper }}
        >
          <DrawerTitleView
            title={title}
            handleClose={() => setDrawerOpened(false)}
          />
          {children}
        </Drawer>
      </>
    );
  }
);
