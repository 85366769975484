import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      fontSize: 16,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(4),
      minHeight: theme.spacing(5),
      display: "flex",
      borderRadius: theme.shape.borderRadius,
      transition: theme.transitions.create(["background-color"], {
        duration: theme.transitions.duration.shortest,
      }),
    },
    body2: {
      fontStyle: "normal",
      fontSize: 11,
      fontWeight: 400,
      color: theme.palette.text.disabled,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
  });

export const TreeLabel = withStyles(styles)(
  ({
    label,
    caption,
    classes,
  }: { label: string; caption?: string } & WithStyles<typeof styles>) => {
    return (
      <Grid
        container
        direction="column"
        justify={caption ? "flex-start" : "center"}
        alignItems="flex-start"
        wrap="nowrap"
        className={classes.root}
      >
        {label}
        {caption && (
          <Grid item style={{ overflow: "hidden" }}>
            <Typography
              variant="body2"
              className={classes.body2}
              color="textSecondary"
              component="div"
            >
              {caption}
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  }
);
