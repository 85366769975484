import { format } from "features/Inputs";
import { Frequency } from "./types";
import {
  sub,
  eachHourOfInterval,
  eachDayOfInterval,
  eachMonthOfInterval,
} from "date-fns";

const FREQ_MAP = {
  H: {
    res: "horária",
    dpt: "horas",
  },
  D: {
    res: "diária",
    dpt: "dias",
  },
  MS: {
    res: "mensal",
    dpt: "meses",
  },
};

export const formatLabel = (startDate: Date, endDate: Date) => {
  const dayBeforeEndDate = sub(endDate, { days: 1 });
  if (startDate.getTime() === dayBeforeEndDate.getTime()) {
    return format(startDate);
  } else {
    return format(startDate) + " — " + format(dayBeforeEndDate);
  }
};

export const genInvalidIntervalErrorMsg = (
  freq: Frequency,
  max_datapoint_num: number
) => {
  return `Para resolução ${FREQ_MAP[freq].res}, selecione um período menor ou
          igual a ${max_datapoint_num} ${FREQ_MAP[freq].dpt}`;
};

export const validateDatapointNum = (
  startDate: Date,
  endDate: Date,
  freq: Frequency,
  max_datapoint_num: number
) => {
  let validation_fn:
    | typeof eachHourOfInterval
    | typeof eachDayOfInterval
    | typeof eachMonthOfInterval;

  if (freq === "H") {
    validation_fn = eachHourOfInterval;
  } else if (freq === "D") {
    validation_fn = eachDayOfInterval;
  } else {
    validation_fn = eachMonthOfInterval;
  }

  // Subtract 1 second from endDate to represent a right-open interval:
  // [startDate, endDate)
  return (
    validation_fn({
      start: startDate,
      end: sub(endDate, { seconds: 1 }),
    }).length <= max_datapoint_num
  );
};
