import theme from "views/Theme";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";
//import { SIDE_BAR_WIDTH } from "../SideBar";

export const FormContentLoader = ({ className }: { className: string }) => {
  return (
    <Grid
      item
      container
      justify="flex-start"
      style={{
        minHeight: "100%",
      }}
      direction="column"
      alignItems="flex-start"
      wrap="nowrap"
    >
      <Box height={`calc(100% - ${theme.spacing(9)}px)`} className={className}>
        <Box mt={2} ml={2} mr={2}>
          <Skeleton
            variant="text"
            height={theme.spacing(7)}
            //width={SIDE_BAR_WIDTH - theme.spacing(4)}
            animation="wave"
          />
        </Box>
        <Box mt={2} ml={2} mr={2}>
          <Skeleton
            variant="text"
            height={theme.spacing(7)}
            //width={SIDE_BAR_WIDTH - theme.spacing(4)}
            animation="wave"
          />
        </Box>
      </Box>
      <Box p={2}>
        <Skeleton
          variant="text"
          height={theme.spacing(7)}
          //width={SIDE_BAR_WIDTH - theme.spacing(4)}
          animation="wave"
        />
      </Box>
    </Grid>
  );
};
