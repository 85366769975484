import Box from "@material-ui/core/Box";
import theme from "views/Theme";

interface DividerProps {
  orientation?: "horizontal" | "vertical";
}

export const Divider = ({ orientation = "horizontal" }: DividerProps) => {
  return orientation === "horizontal" ? (
    <Box minWidth={24}>
      <div
        style={{
          borderBottom: `1px dashed ${theme.palette.text.disabled}`,
          width: "16px",
          margin: "0 auto",
        }}
      />
    </Box>
  ) : (
    <Box minHeight={24} display="flex" alignItems="center">
      <div
        style={{
          borderRight: `1px dashed ${theme.palette.text.disabled}`,
          height: "16px",
          margin: "0 auto",
        }}
      />
    </Box>
  );
};
