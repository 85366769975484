import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    visualBaseRootNormal: {
      backgroundColor: theme.palette.primary.dark,
      borderRadius: theme.spacing(1),
      margin: theme.spacing(2),
      marginBottom: 0,
      marginRight: 0,
    },
    visualBaseRootExpanded: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      height: "100%",
      backgroundColor: theme.palette.primary.dark,
      borderRadius: theme.spacing(1),
      margin: 0,
      padding: theme.spacing(2),
      "& .MuiCardHeader-content": {
        paddingRight: theme.spacing(4),
      },
    },
    visualBaseContentDesktop: {
      display: "flex",
      justifyContent: "center",
      minWidth: "220px",
      width: "100%",
      padding: 0,
      paddingBottom: "0px !important",
    },
    visualBaseContentMobile: {
      display: "flex",
      justifyContent: "center",
      height: "200px",
      width: "100%",
      padding: 0,
      paddingBottom: "0px !important",
    },
    visualBaseContentExpanded: {
      display: "flex",
      justifyContent: "center",
      minWidth: "220px",
      width: "100%",
      height: "100%",
      padding: 0,
      paddingBottom: "0px !important",
    },
  });
