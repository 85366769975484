import { ReactNode, forwardRef } from "react";
import Grid, { GridProps } from "@material-ui/core/Grid";
import { styles } from "./styles";
import { withStyles, WithStyles } from "@material-ui/core";

interface IMainContentProps extends WithStyles<typeof styles> {
  children: ReactNode;
}

const MainContentView = forwardRef(
  (
    { children, ...gridProps }: IMainContentProps & Omit<GridProps, "innerRef">,
    ref
  ) => {
    return (
      <Grid {...gridProps} innerRef={ref}>
        {children}
      </Grid>
    );
  }
);

export default withStyles(styles)(MainContentView);
