import { useState, MouseEvent } from "react";
import Avatar from "@material-ui/core/Avatar";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import theme from "views/Theme";
import Box from "@material-ui/core/Box";
import ScrollTrigger from "utils/ScrollTrigger";
import { useIsDesktop } from "utils/hooks";
import { GENERATION_PANEL_ID } from "features/GenerationPanel";
import { createStyles, withStyles, WithStyles, Theme, lighten } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    menuButton: {
      maxHeight: theme.spacing(5),
      maxWidth: theme.spacing(5),
      padding: theme.spacing(1),
      background: lighten(theme.palette.primary.dark, 0.1),
      "& img": {
        width: theme.spacing(3),
        height: theme.spacing(3),
      },
    },
  })
;

interface IProfileProps {
  user: {
    name: string;
    image: string;
  };
  handleLogout: () => void;
  handleProfile: () => void;
  toggleGreetings?: boolean;
}

export const Profile = withStyles(styles)(({
  user,
  handleLogout,
  handleProfile,
  toggleGreetings,
  classes,
}: IProfileProps & WithStyles<typeof styles>) => {
  // FIX: panelViewHTMLElement is coming null
  const panelViewHTMLElement: HTMLElement | null = document.getElementById(
    GENERATION_PANEL_ID
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const isDesktop = useIsDesktop();

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Grid
        container
        direction="row"
        justify="flex-end"
        alignItems="center"
        wrap="nowrap"
      >
        {user && user.name && (
          <>
            {!isDesktop ? (
              <>
                {panelViewHTMLElement && toggleGreetings ? (
                  <ScrollTrigger
                    target={panelViewHTMLElement}
                    hideOnTrigger={true}
                  >
                    <Box marginRight={theme.spacing(1 / 6)}>
                      <Typography
                        variant="subtitle1"
                        color="textPrimary"
                        noWrap
                      >
                        {`Olá, ${user.name}!`}
                      </Typography>
                    </Box>
                  </ScrollTrigger>
                ) : (
                  <Box marginRight={theme.spacing(1 / 6)}>
                    <Typography variant="subtitle1" color="textPrimary" noWrap>
                      {`Olá, ${user.name}!`}
                    </Typography>
                  </Box>
                )}
              </>
            ) : (
              <Box marginRight={theme.spacing(1 / 6)}>
                <Typography variant="subtitle1" color="textPrimary" noWrap>
                  {`Olá, ${user.name}!`}
                </Typography>
              </Box>
            )}
          </>
        )}

        <IconButton
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
          className={classes.menuButton}
        >
          <Avatar alt="current user" src={user.image} />
        </IconButton>
        <Menu
          id="menu-profile"
          getContentAnchorEl={null}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={open}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              handleLogout();
              handleClose();
            }}
          >
            {user.name ? "Sair" : "Autenticar"}
          </MenuItem>
        </Menu>
      </Grid>
    </div>
  );
});
