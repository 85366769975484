import { PanelSelect, TPanelFormChildrenProps } from "features/BasePanel";
import { IComparisonPanelData } from "services/api";
import { useIsDesktop } from "utils/hooks";

export const AssetSelectors = ({
  panelId,
  data,
}: TPanelFormChildrenProps<IComparisonPanelData>) => {
  const isDesktop = useIsDesktop();
  return (
    <>
      {data && (
        <>
          <PanelSelect
            id="primaryAsset"
            panelId={panelId}
            label="Ativo:"
            title="Selecione o Ativo"
            searchLabel="Pesquisar"
            idField="id"
            labelField="label"
            nestedField="children"
            data={data.assets}
            initState={{ status: "enabled", value: data.assets }}
            mt={isDesktop ? 4 : 2}
          />
          <PanelSelect
            id="secondaryAsset"
            panelId={panelId}
            label="Comparado com:"
            title="Selecione o Ativo"
            searchLabel="Pesquisar"
            idField="id"
            labelField="label"
            nestedField="children"
            data={data.assets}
            initState={{ status: "enabled", value: data.assets }}
            mb={4}
          />
        </>
      )}
    </>
  );
};
