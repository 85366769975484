import Grid from "@material-ui/core/Grid";
import { IBasePanelChildrenProps } from "features/BasePanel";
import VisualListView from "views/VisualListView";
import { DefaultSummaryVisual } from "features/DefaultSummaryVisual";
import { DefaultChartVisual } from "features/DefaultChartVisual";
import { IAssetNode, IGenerationPanelData, IPeriod } from "services/api";
import { VisualType } from "services/api";
import { useIsDesktop } from "utils/hooks";
import { withStyles, WithStyles } from "@material-ui/core";
import theme from "views/Theme";
import { styles } from "./styles";
import { useAppSelector } from "redux/hooks";
import { selectSelectorProp } from "features/BasePanel/redux";
import { PanelState } from "features/BasePanel/PanelPeriodSelect/types";

type TPanelVisualsProps = IBasePanelChildrenProps<IGenerationPanelData>;

export const PanelVisuals = withStyles(styles)(
  ({
    panelId,
    data,
    classes,
  }: TPanelVisualsProps & WithStyles<typeof styles>) => {
    const isDesktop = useIsDesktop();

    const asset = useAppSelector((state) =>
      selectSelectorProp(state, {
        panelId,
        selectorId: "asset",
        prop: "value",
      })
    ) as IAssetNode | undefined;

    const period = useAppSelector((state) =>
      selectSelectorProp(state, {
        panelId,
        selectorId: "period",
        prop: "value",
      })
    ) as PanelState | undefined;

    const periods = period && [
      {
        id: "99",
        label: period.label,
        startIso: period.startDate,
        endIso: period.endDate,
        freq: period.freq,
      } as IPeriod,
    ];

    return (
      <Grid
        item
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        wrap={isDesktop ? "nowrap" : "wrap"}
        style={!isDesktop ? { paddingBottom: theme.spacing(2) } : undefined}
      >
        <VisualListView
          maxWidthDesktop="316px"
          paddingDesktop={`0 0 ${theme.spacing(2)}px 0`}
          direction="column"
          alignItems="stretch"
          wrap="nowrap"
        >
          {/* Move this checking to convPanelPeriodsToVisualInputPeriods */}
          {data &&
            data.periods &&
            asset &&
            periods &&
            data.visualList.map((visual) => {
              return visual.type === VisualType.DEFAULT_SUMMARY ? (
                <DefaultSummaryVisual
                  key={visual.id}
                  id={visual.id}
                  assets={[asset.id]}
                  periods={periods}
                />
              ) : null;
            })}
        </VisualListView>
        <VisualListView
          paddingDesktop={`0 ${theme.spacing(2)}px ${theme.spacing(2)}px 0`}
          direction="row"
          alignItems="flex-start"
          alignContent="flex-start"
          wrap="wrap"
        >
          {data &&
            data.periods &&
            asset &&
            periods &&
            data.visualList.map((visual) => {
              return visual.type === VisualType.CHART ? (
                <DefaultChartVisual
                  key={visual.id}
                  id={visual.id}
                  assets={[asset.id]}
                  periods={periods}
                />
              ) : null;
            })}
        </VisualListView>
      </Grid>
    );
  }
);
