import { useState, useEffect } from "react";
import {
  IAssetNode,
  IComparisonPanelData,
  IMetricListItem,
  IPanelPeriod,
  IPeriod,
  VisualType,
} from "services/api";
import Grid from "@material-ui/core/Grid";
import { withStyles, WithStyles, Theme, createStyles } from "@material-ui/core";
import { useIsDesktop } from "utils/hooks";
import VisualListView from "views/VisualListView";
import { ComparisonSummaryVisual } from "features/ComparisonSummaryVisual";
import { ComparisonChartVisual } from "features/ComparisonChartVisual";
import theme from "views/Theme";
import {
  appBarSpacing,
  toolbarPanelSpacing,
} from "views/MainContentView/styles";
import { desktopBreakpoint } from "utils/hooks/useIsDesktop";
import { titlePanelSpacing } from "./styles";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { selectPanelProp, updatePanels } from "features/BasePanel/redux";
import { IBasePanelChildrenProps } from "features/BasePanel";
import { TPanel } from "features/BasePanel/redux/slice";
import { PanelState } from "features/BasePanel/PanelPeriodSelect/types";

const styles = (theme: Theme) =>
  createStyles({
    mainContent: {
      height: `calc(100vh - ${theme.spacing(
        appBarSpacing + toolbarPanelSpacing + titlePanelSpacing
      )}px)`,
      overflowY: "auto",
      width: "100%",
      paddingBottom: theme.spacing(1),
    },
    content: {
      paddingBottom: theme.spacing(9),
      [theme.breakpoints.up(desktopBreakpoint)]: {
        minHeight: "100%",
        // TODO: make it responsive instead of hard coding it
        width: `calc(100% - ${316 + theme.spacing(1)}px)`,
        overflow: "auto",
        paddingBottom: 0,
      },
    },
  });

type TPanelVisualsProps = IBasePanelChildrenProps<IComparisonPanelData>;

const SECONDARY_METRIC_COLORS = ["rgb(215, 178, 23)", "rgba(215, 178, 23, .5)"];

const parsePrimaryPeriod = (period: PanelState) => {
  const parsedPeriod = JSON.parse(JSON.stringify(period));
  return {
    id: parsedPeriod.periodId,
    label: parsedPeriod.label,
    startIso: parsedPeriod.startDate,
    endIso: parsedPeriod.endDate,
    freq: parsedPeriod.freq,
  } as IPeriod;
};

const parseSecondaryPeriod = (period: IPanelPeriod): IPeriod => {
  const parsedPeriod = JSON.parse(JSON.stringify(period));
  delete parsedPeriod.startLabel;
  delete parsedPeriod.endLabel;
  return parsedPeriod as IPeriod;
};

export const PanelVisuals = withStyles(styles)(
  ({
    panelId,
    data,
    classes,
  }: TPanelVisualsProps & WithStyles<typeof styles>) => {
    const isDesktop = useIsDesktop();
    const dispatch = useAppDispatch();

    const isSubmitting = useAppSelector((state) =>
      selectPanelProp(state, {
        panelId,
        prop: "isSubmitting",
      })
    ) as boolean | undefined;

    const selectors = useAppSelector((state) =>
      selectPanelProp(state, {
        panelId,
        prop: "selectors",
      })
    ) as TPanel["selectors"] | undefined;

    const [assets, setAssets] = useState<string[]>();
    const [periods, setPeriods] = useState<IPeriod[]>();
    const [metrics, setMetrics] = useState<string[]>();
    const [primaryMetricIdState, setPrimaryMetricIdState] = useState<
      string[]
    >();
    const [secondaryMetricIdState, setSecondaryMetricIdState] = useState<
      string[]
    >();

    useEffect(() => {
      if (
        isSubmitting &&
        data &&
        data.primaryPeriods &&
        data.secondaryPeriods &&
        selectors &&
        selectors.primaryAsset &&
        selectors.secondaryAsset &&
        selectors.primaryAsset.value &&
        selectors.secondaryAsset.value &&
        selectors.primaryPeriod &&
        selectors.secondaryPeriod &&
        selectors.primaryPeriod.value &&
        selectors.secondaryPeriod.value &&
        selectors.primaryMetric &&
        selectors.primaryMetric.value &&
        selectors.secondaryMetric
      ) {
        const primaryAssetId = (selectors.primaryAsset.value as IAssetNode).id;
        const secondaryAssetId = (selectors.secondaryAsset.value as IAssetNode)
          .id;

        setAssets([primaryAssetId, secondaryAssetId]);

        const primaryPeriod = selectors.primaryPeriod.value as PanelState;
        const secondaryPeriod = selectors.secondaryPeriod.value as IPanelPeriod;

        setPeriods([
          parsePrimaryPeriod(primaryPeriod),
          parseSecondaryPeriod(secondaryPeriod),
        ]);

        const primaryMetricId = (selectors.primaryMetric
          .value as IMetricListItem).id;
        const secondaryMetric = selectors.secondaryMetric.value as
          | IMetricListItem
          | undefined;
        const secondaryMetricId = secondaryMetric && secondaryMetric.id;

        if (
          secondaryMetricId &&
          selectors.secondaryMetric.status === "enabled"
        ) {
          setMetrics([primaryMetricId, secondaryMetricId]);
          setSecondaryMetricIdState([secondaryMetricId]);
        } else {
          setMetrics([primaryMetricId]);
          setSecondaryMetricIdState(undefined);
        }
        setPrimaryMetricIdState([primaryMetricId]);

        dispatch(
          updatePanels({
            [panelId]: {
              isSubmitting: false,
            },
          })
        );
      }
    }, [panelId, data, selectors, isSubmitting, dispatch]);

    return (
      <Grid
        item
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        wrap={isDesktop ? "nowrap" : "wrap"}
        classes={{ root: classes.content }}
      >
        <>
          <VisualListView
            maxWidthDesktop="400px"
            paddingDesktop={`0 0 ${theme.spacing(2)}px 0`}
            direction="column"
            alignItems="stretch"
            wrap="nowrap"
          >
            {data?.visualList &&
              data.visualList.map((visual) => {
                return visual.type === VisualType.COMPARISON_SUMMARY ? (
                  <ComparisonSummaryVisual
                    id={visual.id}
                    key={visual.id}
                    assets={assets}
                    periods={periods}
                    metrics={metrics}
                    disable={false}
                  />
                ) : null;
              })}
          </VisualListView>
          <VisualListView
            paddingDesktop={`0 ${theme.spacing(1)}px ${theme.spacing(2)}px 0`}
            direction="row"
            alignItems="flex-start"
            alignContent="flex-start"
            wrap="wrap"
          >
            <ComparisonChartVisual
              id={"9"}
              key={"9"}
              assets={assets}
              periods={periods}
              metrics={metrics}
              disable={false}
            />
            <ComparisonChartVisual
              id={"10"}
              key={"10"}
              assets={assets}
              periods={periods}
              metrics={primaryMetricIdState}
              disable={false}
            />
            {secondaryMetricIdState && (
              <ComparisonChartVisual
                id={"10"}
                key={"11"}
                assets={assets}
                periods={periods}
                metrics={secondaryMetricIdState}
                colors={SECONDARY_METRIC_COLORS}
                disable={false}
              />
            )}
          </VisualListView>
        </>
      </Grid>
    );
  }
);
