import {
  useState,
  useRef,
  useEffect,
  useCallback,
  ComponentProps,
} from "react";
import { Select, SelectProps, format, toISO, fromISO } from "features/Inputs";
import { PanelDatePicker } from "./PanelDatePicker";
import { FreqPicker } from "./FreqPicker";
import { Divider } from "./Divider";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDivider from "@material-ui/core/Divider";
import DateRangeIcon from "@material-ui/icons/DateRange";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Button from "views/ButtonView";
import { withStyles, WithStyles } from "@material-ui/core";
import { styles } from "./styles";
import theme from "views/Theme";
import { useIsDesktop } from "utils/hooks";
import { makePanelInput } from "../makePanelInput";
import { IPanelPeriod } from "services/api";
import { sub } from "date-fns";
import {
  validateDatapointNum,
  formatLabel,
  genInvalidIntervalErrorMsg,
} from "./utils";
import { PanelState, Frequency, ErrorState } from "./types";
import { PanelInput } from "..";

type PeriodSelectProps = {
  maxDatapointNum: { [key in Frequency]: number };
} & SelectProps &
  WithStyles<typeof styles>;

const StyledPeriodSelect = withStyles(styles)(
  ({
    classes,
    value: valueProp,
    onChange,
    data,
    maxDatapointNum,
    ...other
  }: PeriodSelectProps) => {
    const isDesktop = useIsDesktop();

    const value = valueProp as PanelState;

    const periods = data as IPanelPeriod[];
    const [periodId, setPeriodId] = useState<string>(value.periodId);

    const [label, setLabel] = useState<string | undefined>(value.label);

    const [startDate, setStartDate] = useState<Date | null>(
      fromISO(value.startDate)
    );
    const [endDate, setEndDate] = useState<Date | null>(fromISO(value.endDate));
    const [freq, setFreq] = useState(value.freq as Frequency);

    const { current: maxDate } = useRef(
      sub(fromISO(periods[0].endIso), { days: 1 })
    );
    const maxDateStr = format(maxDate);

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isSelectMenuOpen, setIsSelectMenuOpen] = useState(false);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

    const [globalError, setGlobalError] = useState<string>("");
    const [
      startDatePickerErrorState,
      setStartDatePickerErrorState,
    ] = useState<ErrorState>({ enabled: false });
    const [
      endDatePickerErrorState,
      setEndDatePickerErrorState,
    ] = useState<ErrorState>({ enabled: false });

    const [
      isCustomPeriodSelected,
      setIsCustomPeriodSelected,
    ] = useState<boolean>(false);

    const handleCancel = () => {
      setIsDialogOpen(false);
      setStartDate(fromISO(value.startDate));
      setEndDate(fromISO(value.endDate));
      setFreq(value.freq as Frequency);
    };

    const isIntervalValid = useCallback(
      () =>
        startDate &&
        !isNaN(startDate.getTime()) &&
        endDate &&
        !isNaN(endDate.getTime()) &&
        startDate <= maxDate &&
        sub(endDate, { days: 1 }) <= maxDate &&
        startDate < endDate,
      [startDate, endDate, maxDate]
    );

    useEffect(() => {
      if (isIntervalValid()) {
        if (
          validateDatapointNum(
            startDate as Date,
            endDate as Date,
            freq,
            maxDatapointNum[freq]
          )
        ) {
          if (globalError) {
            setGlobalError("");
          }
          if (startDatePickerErrorState.enabled) {
            setStartDatePickerErrorState({
              enabled: false,
            });
          }
          if (endDatePickerErrorState.enabled) {
            setEndDatePickerErrorState({
              enabled: false,
            });
          }
          if (isSubmitDisabled) {
            setIsSubmitDisabled(false);
          }
        } else {
          setGlobalError(
            genInvalidIntervalErrorMsg(freq, maxDatapointNum[freq])
          );
          setStartDatePickerErrorState({ enabled: true });
          setEndDatePickerErrorState({ enabled: true });
          setIsSubmitDisabled(true);
        }
      }
    }, [
      startDate,
      endDate,
      freq,
      globalError,
      startDatePickerErrorState.enabled,
      endDatePickerErrorState.enabled,
      isSubmitDisabled,
      isIntervalValid,
      maxDatapointNum,
    ]);

    const handleStartDate = (date: Date | null) => {
      if (date) {
        if (isNaN(date.getTime())) {
          setStartDatePickerErrorState({
            enabled: true,
            text: `Data inválida. Exemplo válido: ${maxDateStr}`,
          });
          setIsSubmitDisabled(true);
        } else if (date > maxDate) {
          setStartDatePickerErrorState({
            enabled: true,
            text: `Data deve ser inferior ou igual a ${maxDateStr}`,
          });
          setIsSubmitDisabled(true);
        } else if (endDate && date >= endDate) {
          setStartDatePickerErrorState({
            enabled: true,
            text: "Data inicial deve ser anterior ou igual à data final",
          });
          setIsSubmitDisabled(true);
        } else {
          if (startDatePickerErrorState.enabled) {
            setStartDatePickerErrorState({
              enabled: false,
            });
          }
          if (isSubmitDisabled && !endDatePickerErrorState.enabled) {
            setIsSubmitDisabled(false);
          }
        }
      }
      setStartDate(date);
    };

    const handleEndDate = (date: Date | null) => {
      if (date) {
        if (isNaN(date.getTime())) {
          setEndDatePickerErrorState({
            enabled: true,
            text: `Data inválida. Exemplo válido: ${maxDateStr}`,
          });
          setIsSubmitDisabled(true);
        } else if (sub(date, { days: 1 }) > maxDate) {
          setEndDatePickerErrorState({
            enabled: true,
            text: `Data deve ser inferior ou igual a ${maxDateStr}`,
          });
          setIsSubmitDisabled(true);
        } else if (startDate && startDate >= date) {
          setEndDatePickerErrorState({
            enabled: true,
            text: "Data final deve ser posterior ou igual à data inicial",
          });
          setIsSubmitDisabled(true);
        } else {
          if (endDatePickerErrorState.enabled) {
            setEndDatePickerErrorState({
              enabled: false,
            });
          }
          if (isSubmitDisabled && !startDatePickerErrorState.enabled) {
            setIsSubmitDisabled(false);
          }
        }
      }
      setEndDate(date);
    };

    const updatePanelState = (
      periodId: string,
      startDate: Date,
      endDate: Date,
      freq: Frequency
    ) => {
      const startDateISO = toISO(startDate);
      const endDateISO = toISO(endDate);

      if (
        value === undefined ||
        (value &&
          (periodId !== value.periodId ||
            startDateISO !== value.startDate ||
            endDateISO !== value.endDate ||
            freq !== value.freq))
      ) {
        const newLabel = formatLabel(startDate, endDate);

        onChange({
          periodId,
          label: newLabel,
          startDate: startDateISO,
          endDate: endDateISO,
          freq,
        });

        setLabel(newLabel);
        setPeriodId(periodId);
        setStartDate(startDate);
        setEndDate(endDate);
        setFreq(freq);
      }
    };

    const updatePeriodId = (value: PanelInput["value"]) => {
      // TODO: fix this type assertion
      const period = (value as unknown) as IPanelPeriod;

      updatePanelState(
        period.id,
        fromISO(period.startIso),
        fromISO(period.endIso),
        period.freq
      );

      setIsCustomPeriodSelected(false);
    };

    const CustomPeriodItem = () => {
      return (
        <>
          <MenuItem
            onClick={() => setIsDialogOpen(true)}
            classes={{ selected: classes.itemSelected, root: classes.item }}
            selected={isCustomPeriodSelected}
          >
            <ListItemIcon style={{ minWidth: theme.spacing(5) }}>
              <DateRangeIcon fontSize="small" color="disabled" />
            </ListItemIcon>
            <ListItemText
              primary="Período customizado"
              secondary={label}
              secondaryTypographyProps={{
                style: { color: theme.palette.text.disabled, fontSize: 11 },
              }}
            />
          </MenuItem>
          <MuiDivider
            variant="fullWidth"
            component="li"
            style={{
              backgroundColor: theme.palette.text.disabled,
              marginTop: theme.spacing(1),
              marginBottom: theme.spacing(1),
            }}
          />
        </>
      );
    };

    const customPeriodItem = {
      id: "99",
      label: "Período customizado",
      node: <CustomPeriodItem />,
    };

    const handleSubmit = () => {
      updatePanelState(
        customPeriodItem.id,
        startDate as Date,
        endDate as Date,
        freq
      );
      setIsCustomPeriodSelected(true);
      setIsDialogOpen(false);
      setIsSelectMenuOpen(false);
    };

    return (
      <>
        <Select
          {...other}
          value={
            periodId === customPeriodItem.id
              ? {
                  id: customPeriodItem.id,
                  label: customPeriodItem.label,
                }
              : periods[Number(periodId)]
          }
          data={periods}
          onChange={updatePeriodId}
          open={isSelectMenuOpen}
          onOpen={() => setIsSelectMenuOpen(true)}
          onClose={() => setIsSelectMenuOpen(false)}
          extraItem={customPeriodItem}
        />
        <Dialog
          open={isDialogOpen}
          scroll="paper"
          onClose={handleCancel}
          classes={{ paper: classes.dialogPaper }}
        >
          <DialogContent className={classes.dialogContent}>
            <Grid container spacing={3} wrap="nowrap" direction="column">
              <Grid item xs={12}>
                <Grid
                  container
                  wrap="nowrap"
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography variant="subtitle1">
                      Selecionar período customizado
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton aria-label="close" onClick={handleCancel}>
                      <CloseIcon color="disabled" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              {globalError && (
                <Alert className={classes.alert} severity="error">
                  {globalError}
                </Alert>
              )}
              <Grid item xs={12}>
                <Grid
                  container
                  direction={isDesktop ? "row" : "column"}
                  wrap="nowrap"
                  alignItems="center"
                >
                  <Grid item className={classes.datePickerGrid}>
                    <Paper elevation={0} className={classes.datePickerPaper}>
                      <PanelDatePicker
                        id="startDate"
                        label="Data inicial"
                        value={startDate}
                        onChange={handleStartDate}
                        error={startDatePickerErrorState.enabled}
                        errorText={startDatePickerErrorState.text}
                        maxDate={maxDate}
                        classes={{ root: classes.datePicker }}
                        bypass
                      />
                    </Paper>
                  </Grid>
                  {isDesktop ? (
                    <Divider orientation="horizontal" />
                  ) : (
                    <Divider orientation="vertical" />
                  )}
                  <Grid item className={classes.datePickerGrid}>
                    <Paper elevation={0} className={classes.datePickerPaper}>
                      <PanelDatePicker
                        id="endDate"
                        label="Data final"
                        value={endDate}
                        onChange={handleEndDate}
                        error={endDatePickerErrorState.enabled}
                        errorText={endDatePickerErrorState.text}
                        maxDate={maxDate}
                        classes={{ root: classes.datePicker }}
                      />
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  direction={isDesktop ? "row" : "column"}
                  wrap="nowrap"
                  justify={isDesktop ? "space-between" : "flex-start"}
                  alignItems={isDesktop ? "center" : "flex-start"}
                  spacing={1}
                >
                  <Grid
                    item
                    className={
                      isDesktop
                        ? classes.freqPickerDesktop
                        : classes.freqPickerMobile
                    }
                  >
                    <Typography variant="subtitle2">
                      Visualizar período customizado por:
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    className={
                      isDesktop
                        ? classes.freqPickerDesktop
                        : classes.freqPickerMobile
                    }
                  >
                    <FreqPicker
                      freq={freq}
                      onClick={setFreq}
                      disabled={!isIntervalValid()}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  wrap="nowrap"
                  justify="flex-end"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <Button
                      type="submit"
                      variant="contained"
                      color="secondary"
                      onClick={handleCancel}
                      disableElevation
                    >
                      CANCELAR
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      isSubmitting={false}
                      variant="contained"
                      color="primary"
                      disableElevation
                      disabled={isSubmitDisabled}
                      onClick={handleSubmit}
                    >
                      APLICAR
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </>
    );
  }
);

const WrappedPanelPeriodSelect = makePanelInput(StyledPeriodSelect);

export const PanelPeriodSelect = ({
  initState: initStateProp,
  data,
  ...other
}: ComponentProps<typeof WrappedPanelPeriodSelect>) => {
  const defaultValue = (data as IPanelPeriod[])[0];
  const initState = {
    value: {
      periodId: defaultValue.id,
      label: formatLabel(
        fromISO(defaultValue.startIso),
        fromISO(defaultValue.endIso)
      ),
      startDate: defaultValue.startIso,
      endDate: defaultValue.endIso,
      freq: defaultValue.freq,
    },
    ...initStateProp,
  };
  return (
    <WrappedPanelPeriodSelect data={data} initState={initState} {...other} />
  );
};
