import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    dialogPaper: {
      backgroundColor: theme.palette.primary.dark,
      maxWidth: "650px",
    },
    dialogContent: {
      padding: theme.spacing(3),
    },
    datePickerGrid: {
      width: "100%",
    },
    datePickerPaper: {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    datePicker: {
      width: "100%",
    },
    freqPickerDesktop: {
      width: "100%",
      paddingLeft: `${theme.spacing(1.5)}px !important`,
    },
    freqPickerMobile: {
      width: "100%",
    },
    body1: {
      fontStyle: "normal",
      fontSize: 18,
      fontWeight: 700,
    },
    item: {
      borderRadius: theme.shape.borderRadius,
      color: theme.palette.text.secondary,
    },
    itemSelected: {
      paddingRight: theme.spacing(4),
      color: theme.palette.text.primary,
      backgroundColor: `${theme.palette.background.paper} !important`,
      "&:hover": {
        backgroundColor: `${theme.palette.action.hover} !important`,
      },
      "&:before": {
        content: `"\\e876"`,
        fontFamily: "Material Icons",
        fontSize: theme.typography.button.fontSize,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: theme.spacing(5),
        minWidth: theme.spacing(3),
        position: "absolute",
        right: 0,
      },
    },
    alert: {
      width: "100%",
      backgroundColor: "transparent",
      color: theme.palette.error.main,
      paddingTop: 0,
      paddingBottom: 0,
    },
  });
