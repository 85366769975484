import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "views/ButtonView";
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core";
import { Frequency } from "./types";

const StyledButton = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: "#081F2B",
    color: theme.palette.text.primary,
    borderRadius: theme.spacing(0.5),
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
    "&:active": {
      color: theme.palette.text.primary,
    },
    "&:disabled": {
      backgroundColor: "#081F2B !important",
      color: theme.palette.text.disabled,
    },
  },
  containedPrimary: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
  },
}))(Button);

const styles = (theme: Theme) =>
  createStyles({
    groupButton: {
      height: 39,
      padding: theme.spacing(0.5),
      backgroundColor: "#081F2B",
    },
    groupButtonChildren: {
      "&:not(:last-child)": {
        borderRight: `${theme.spacing(1)}px solid #081F2B !important`,
      },
    },
  });

interface FreqPickerProps extends WithStyles<typeof styles> {
  freq: Frequency;
  onClick: (freq: Frequency) => void;
  disabled: boolean;
}

const handleColor = (isSelected: boolean) => {
  return isSelected ? "primary" : "secondary";
};

export const FreqPicker = withStyles(styles)(
  ({ freq, onClick, disabled, classes }: FreqPickerProps) => {
    return (
      <ButtonGroup
        fullWidth
        variant="contained"
        disableElevation
        classes={{
          contained: classes.groupButton,
          groupedContainedHorizontal: classes.groupButtonChildren,
        }}
        size="small"
      >
        <StyledButton
          color={handleColor(freq === "H")}
          onClick={() => onClick("H")}
          disabled={disabled}
        >
          HORA
        </StyledButton>
        <StyledButton
          color={handleColor(freq === "D")}
          onClick={() => onClick("D")}
          disabled={disabled}
        >
          DIA
        </StyledButton>
        <StyledButton
          color={handleColor(freq === "MS")}
          onClick={() => onClick("MS")}
          disabled={disabled}
        >
          MÊS
        </StyledButton>
      </ButtonGroup>
    );
  }
);
